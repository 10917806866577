import React from 'react';
import breezyCoocktail from "../../assets/images/Breezycocktail.png";
import loveHungry from "../../assets/images/lovehungry.png";
import noteTaker from '../../assets/images/Note-Taker.png';
import weather from '../../assets/images/weather-dashboard.png';
import eCommerce from '../../assets/images/e-commerce.png';
import employeeTracker from '../../assets/images/employeeTracker.png'
import '../../assets/css/style.css'
import { BiMailSend } from "react-icons/bi";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import { Row, Col, Container } from 'react-bootstrap';


export default function Portfolio() {
  return (
    <Container>
      <Row>
        <Col>
          <p style={{ textAlign: 'center' ,marginTop:'40px'}}>  
            This application was created for the purposes of assisting the user with making a choice on
            their drinking preference.  <br />
            The application solves the problem of not knowing what exact drink you want as well 
            as finding a drink that you may<br />
            want or find interesting considering the ingredients you specifically want.<br />
            Language used: Html,Css,JavaScript,Bootstrap,Jquery.<br />
            <a href="https://github.com/keimdm/recipe-search">GitHub Link </a>|
            <a href="https://keimdm.github.io/recipe-search/">Deployed Link </a>
          </p>
          <img src={breezyCoocktail} alt=" Breezy-Coocktail" className="img-fluid" />
          <hr style={{borderColor:'3px solid black'}}/>

          <p style={{ textAlign: 'center',marginTop:'40px' }}>  Love Hungry
            Dating app based on food preference.<br />
            Language used: Node.js, Handlebars, Sequelize, Bcrypt, Mysql2, Nodemon, Express js,
            Session, Dotenv.<br />
            <a href="https://github.com/JDR8888/love-hungry">GitHub Link </a>|
            <a href="https://love-hungry.herokuapp.com/">Deployed Link </a>
          </p>
          <img src={loveHungry} alt=" love-hungry" className="img-fluid" />
          <hr/>

          <p style={{ textAlign: 'center',marginTop:'40px' }}>  
          Employee_Tracker database is a digital record of employees.<br />
          Database include contact information, job titles, payroll data, and other information related to employee.<br />
          Language used: Node.js,Express package,JavaScript,MySQL2 package,Inquirer package,Console.table package. <br />
            <a href="https://github.com/Hshini/Employee_Tracker">GitHub Link </a>|
            <a href="https://watch.screencastify.com/v/Oc8o0zFMaMe8BuN4KHjj">Video Guide </a>
          </p>
          <img src={employeeTracker} alt='Employee Tracker' className="img-fluid" />
          <hr/>

          <p style={{ textAlign: 'center',marginTop:'40px' }}>  
            Note Taker can be used to write and save notes.<br />
            This application will use an Express.js back end and will 
            save and retrieve note data from a JSON file.<br />
            Language used: Html,Css,JavaScript,Node.js,Express package,JSON <br />
            <a href="https://github.com/Hshini/Note-Taker">GitHub Link </a>|
            <a href="https://note-taker-0011.herokuapp.com/">Deployed Link </a>
          </p>
          <img src={noteTaker} alt='Note Taker' className="img-fluid" />
          <hr/>

          <p style={{ textAlign: 'center',marginTop:'40px' }}>  
            This weather dashboard allows users to search for a city <br />
            to get the current weather and 5 day forecast.<br />
            Language used: Html,Css,JavaScript.<br />
            <a href="https://github.com/Hshini/Weather-Dashboard">GitHub Link </a>|
            <a href="https://hshini.github.io/Weather-Dashboard">Deployed Link </a>
          </p>
          <img src={weather} alt='weather dashboard' className="img-fluid" />
          <hr/>
          
          <p style={{ textAlign: 'center',marginTop:'40px' }}>  
          E-commerce Database,This app uses sequelize orm to create the database
          E-commerce app will ask you questions, <br />
          which will help you to store your information into database.<br />
          Language used: Node.js,Express package,JavaScript,MySQL2 package,Sequelize package ,Dotenv package <br />
            <a href="https://github.com/Hshini/E-Commerce">GitHub Link </a>|
            <a href="https://watch.screencastify.com/v/rmX1DY920IKDKXxXTXmo">Video Guide </a>
          </p>
          <img src={eCommerce} alt='Quiz-Game' className="img-fluid" />
        </Col>
      </Row>
      <footer style={{ display: 'flex', marginTop: '20px', flexDirection: 'row', justifyContent: 'center' }}>
        <h1>
          <a href='https://github.com/Hshini' style={{ marginRight: '15px' }} ><BsGithub /></a>
        </h1>
        <h1>
          <a href='https://www.linkedin.com/in/halim-shini-79791b18a' style={{ marginRight: '15px' }}> <BsLinkedin /></a>
        </h1>
        <h1>
          <a href="mailto:halimshini@hotmail.com" style={{ marginRight: '15px' }}><BiMailSend /></a>
        </h1>
      </footer>
    </Container>
  );
}

import React from 'react';
import { BiMailSend } from "react-icons/bi";
import { BsGithub,BsLinkedin, } from "react-icons/bs";
import {Row,Col} from 'react-bootstrap';
import profilePic from '../../assets/images/Halim.jpg'

export default function About() {
  document.body.style.backgroundColor = "#f0f2f6";
  return (
    <>
      <Row>
        <Col xl={8}>
          <h2 style={{textAlign:'center',marginTop:'30px'}}> A little bit about me !</h2><br/>
          <h4 style={{marginLeft:'50px',fontSize:'18px',lineHeight:'40px'}}>
          Hi, I’m  Halim a Full Stack Developer with a Bachelor's degree in Information Technology,<br/>
          and a Certification from Columbia Engineering Coding Boot Camp .<br/>
          My experience with Columbia Engineering  Coding Boot Camp give me a lot strength and<br/>
           thought me how to organize my time
          and be strict with myself.I learned not only how to program.<br/>
          I learned how to be dedicated to what you want to achieve and how to count on myself only.<br/>
          I’m a lifelong learner ,Innovative problem-solver passionate about developing apps.<br/>
          Strengths in creativity, teamwork, and realizing projects on time.<br/>
          </h4>
        </Col>
        <Col xl={3}>
          < img src={profilePic} alt="profile " className="img-fluid" style={{marginTop:'30px',borderRadius:'1000px'}} />
        </Col>
      </Row>
      <footer style={{ display: 'flex', marginTop: '20px', flexDirection: 'row', justifyContent: 'center' }}>
        <h1>
          <a href='https://github.com/Hshini' style={{ marginRight: '15px' }} ><BsGithub /></a>
        </h1>
        <h1>
          <a href='https://www.linkedin.com/in/halim-shini-79791b18a' style={{ marginRight: '15px' }}> <BsLinkedin /></a>
        </h1>
        <h1>
          <a href="mailto:halimshini@hotmail.com" style={{ marginRight: '15px' }}><BiMailSend /></a>
        </h1>
      </footer>
    </>

  );
}
